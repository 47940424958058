import React, { useEffect } from "react";
import Parallax from "parallax-js";

import EmailIcon from "@material-ui/icons/Email";
import SubjectIcon from "@material-ui/icons/Subject";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import AOS from "aos";
import useSound from "use-sound";
import popSound from "../../Assets/sounds/pop-sound.mp3";
import animation from "../../Assets/img/triangle.svg";

import "./styles.scss";

const Social = () => {
  useEffect(() => {
    const scene2 = document.getElementById("scene2");
    new Parallax(scene2);
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);
  const [play, { stop }] = useSound(popSound, { volume: 0.2 });

  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <div
      style={{
        width: "100%",
        height: "600px",
        // backgroundImage:
        //   "linear-gradient(to bottom, #000722f6, #001357f6,#001357f6)",
        // backgroundRepeat: "no-repeat",
        marginTop: "-1px",

        // linear-gradient(to bottom,  #001357f6,#000722f6,#001357f6)
      }}
    >
      <div className="item8" id="scene2">
        {/* blog */}
        <img
          src="assets/planet/astronaut.png"
          id="shapes"
          alt="shape"
          data-depth="-3"
        />
      </div>

      <div className="socialContainer">
        <div className="aboutHeading" data-aos="fade-up">
          <h1>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              Int
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              e
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              rn
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              et
            </span>
            &nbsp;
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              BFF
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              ?
            </span>
          </h1>

          <hr />
        </div>
        <div className="socialContent">
          <img
            src={animation}
            className="rotation shapes"
            alt=""
            style={{
              position: "absolute",
              right: "15%",
              zIndex: "-1",
              width: "100px",
            }}
          />
          <div className="headings" data-aos="fade-up">
            <h1 style={{ color: "#90a6fc" }}>
              <span> Hey you! &nbsp;</span> yes you, I'd love to talk with you!{" "}
              <span style={{ fontWeight: "100" }}> 😋</span>{" "}
            </h1>
            <h2>Reach me out on the these platforms ...</h2>
          </div>
          <div className="social-links" data-aos="fade-up">
            <a
              href="https://linkedin.com/in/hardikk2002"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon className="fab" />
            </a>
            <a
              href="https://github.com/hardikk2002"
              target="_blank"
              rel="noreferrer"
            >
              <GitHubIcon className="fab" />
            </a>
            <a
              href="https://twitter.com/hardikkaushik_"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon className="fab" />
            </a>
          </div>
          <div className="social-links" data-aos="fade-up">
            <a
              href="mailto:hardikk2002@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <EmailIcon className="fab" />
            </a>
            <a
              href="https://hashnode.com/@Hardikk2002"
              target="_blank"
              rel="noreferrer"
            >
              <SubjectIcon className="fab" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
