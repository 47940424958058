import { css, keyframes } from "styled-components";

const spin = keyframes`
from {
  transform: rotate(0);
}
to{
  transform: rotate(359deg);
}
`;

const PlanetsStyles = css`
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${"" /* transform: skew(-3deg); */}
  .orbit {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px dotted #95b0b6;
    border-radius: 50%;
  }

  .earth-orbit {
    width: 170px;
    height: 170px;
    animation: ${spin} 12s linear 0s infinite;
  }

  .venus-orbit {
    width: 140px;
    height: 140px;
    animation: ${spin} 7.4s linear 0s infinite;
  }

  .mercury-orbit {
    width: 95px;
    height: 95px;
    animation: ${spin} 3s linear 0s infinite;
  }

  .planet {
    position: absolute;
    top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #95b0b6;
    box-shadow: 0 0 10px #19cbe7;
  }

  .sun {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #19cbe7;
  }
`;

export default PlanetsStyles;
