import React from 'react'
import "./styles.scss"

function Underlined({ text }) {
    return (
        <div className="Underlined">
            <span>{text}</span>
        </div>
    )
}

export default Underlined
