import React from "react";
import styled from "styled-components";
import ThumbUpAltRoundedIcon from "@material-ui/icons/ThumbUpAltRounded";
import ForumRoundedIcon from "@material-ui/icons/ForumRounded";

const BlogCard = styled.div`
  position: relative;
  height: 100%;
  width: 600px;
  margin: auto 0.5%;

  @media (max-width: 750px) {
    height: auto;
    width: 350px;
    margin: auto 7%;
  }
`;
const BlogInner = styled.div`
  background: rgba(0, 58, 124, 0.15);
  ${"" /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */}
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  ${"" /* border: 1px solid rgba(255, 255, 255, 0.18); */}
  border-radius: 0 10px 10px 0;
  box-shadow: 2px 2px 5px #90a5fcaf;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 3% 5%;
  width: 600px;
  cursor: pointer;
  @media (min-width: 1300px) {
    ${"" /* height: 85%; */}
  }

  h1 {
    color: #f1f1f1;
    font-family: ${(props) => props.theme.content_font};
    font-size: 2rem;
    margin: 0 5% 0% 0;
    @media (max-width: 750px) {
      ${"" /* text-align: center; */}
      margin: 0 0 -3% 0%;
    }
  }
  p {
    color: ${(props) => props.theme.grey};
    font-family: ${(props) => props.theme.normal_font};
    font-weight: 600;
  }

  @media (max-width: 750px) {
    padding: 5%;
    width: 350px;
    display: flex;
    flex-direction: column;

    .blogLinks {
      display: none;
    }
  }
`;
const BlogContent = styled.div`
  margin: auto;
  text-align: left;
  flex-basis: 55%;
  transition: 0.4s ease-in-out;
  @media (max-width: 750px) {
    flex-basis: 100%;
  }
`;

const BlogImage = styled.div`
  flex-basis: 50%;
  margin: auto;

  img {
    height: 200px;
    width: 105%;
    border-radius: 5px;
  }
  @media (max-width: 750px) {
    flex-basis: 100%;
    margin-top: 5%;
  }
`;

function BlogCards({
  imageLink,
  heading,
  smallContent,
  likes,
  comments,
  blogLink,
}) {
  return (
    <BlogCard
    // data-aos="fade-left"
    // data-aos-offset="-150"
    // data-aos-duration="1000"
    >
      <BlogInner onClick={() => window.open(`${blogLink}`, "_blank")}>
        <BlogContent>
          <h1>{heading}</h1>
          <br />
          <p>{smallContent}</p>

          <div className="blogLinks">
            <br />
            <p>
              <ThumbUpAltRoundedIcon /> {likes}
              &nbsp; &nbsp; &nbsp;
              <ForumRoundedIcon /> {comments}
            </p>
          </div>
        </BlogContent>
        <BlogImage>
          <img src={imageLink} alt="xpro" />
        </BlogImage>
      </BlogInner>
    </BlogCard>
  );
}

export default BlogCards;
