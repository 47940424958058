import React from 'react'
import GitHubIcon from '@material-ui/icons/GitHub';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import "./styles.scss"



function ProjectsTwo({ cover, pName, pContent, techs, Github, Youtube, external }) {

    return (
      <div
        className="projectContent"
        style={{
          width: "90%",
          margin: "3% auto",
          display: "flex",

          justifyContent: "center",
          flexFlow: "row wrap",
        }}
      >
        <div
          className="outerContent outerMob"
          style={{
            flexBasis: "45%",
            position: "relative",
            right: "-10%",
            textAlign: "left",
            margin: "auto 0",
            zIndex: "2",
          }}
        >
          <div className="proCont">
            <h3>Featured Project</h3>

            <h2>{pName}</h2>

            <div
              className="innerContent"
              style={{
                width: "fill-content",
                borderRadius: "10px",
              }}
            >
              <p>{pContent}</p>
            </div>

            <h4>{techs}</h4>

            <p>
              {" "}
              {Github && (
                <a href={Github} target="_blank" rel="noreferrer">
                  <GitHubIcon className="icons" />
                </a>
              )}
              &nbsp;
              {Youtube && (
                <a href={Youtube} target="_blank" rel="noreferrer">
                  <YouTubeIcon className="icons centIcon" />
                </a>
              )}
              &nbsp;
              {external && (
                <a href={external} target="_blank" rel="noreferrer">
                  <ExitToAppIcon className="icons" />
                </a>
              )}
            </p>
          </div>
        </div>

        <div
          className="proImage imagMob"
          style={{
            textAlign: "center",
            flexBasis: "45%",
            height: "300px",
            position: "relative",
            right: "10%",
            borderRadius: "12px",
          }}
        >
          {/* <img src={cover} alt=""
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "12px",

                }}

            /> */}
          <iframe
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
            }}
            src={cover}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    );

}

export default ProjectsTwo
