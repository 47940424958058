import React, { useEffect } from "react";
import "./styles.scss";

function Globe() {
  // useEffect(() => {
  //   console.log(document.getElementById("modell").detail.totalProgress);
  // }, []);
  return (
    <div className="moveArrow">
      <div>
        {/* <model-viewer
          id="modell"
          src="assets/planet/location.glb"
          alt="A 3D model of Earth"
          camera-controls
          auto-rotate
        ></model-viewer> */}
        <model-viewer
          src="assets/planet/Earth.glb"
          // ar
          // ar-modes="webxr scene-viewer quick-look"
          camera-controls
          environment-image="neutral"
          poster="poster.webp"
          shadow-intensity="0"
          exposure="2"
          auto-rotate
          camera-orbit="-74.93deg 71.71deg auto"
          shadow-softness="1"
        >
          <button
            class="Hotspot1"
            slot="hotspot-3"
            data-position="-456.4036141189474m 178.00950243264276m -97.90816842579267m"
            data-normal="-0.9152905031046583m 0.3299728159909111m -0.2310005100285496m"
            data-visibility-attribute="visible"
          >
            <div class="HotspotAnnotation">I am here!</div>
          </button>
          <button
            class="Hotspot"
            slot="hotspot-5"
            data-position="-438.74202804569575m 185.38318901379887m 151.68394904019783m"
            data-normal="-0.8583449327132443m 0.40341338936841414m 0.3170198949021727m"
            data-visibility-attribute="visible"
          >
            <div class="HotspotAnnotation">📌 Hong Kong</div>
          </button>
          <button
            class="Hotspot"
            slot="hotspot-6"
            data-position="-28.22995600804972m 363.4475209832722m -341.23360849118234m"
            data-normal="-0.039314516372159686m 0.742767903272851m -0.6683937542123475m"
            data-visibility-attribute="visible"
          >
            <div class="HotspotAnnotation">📌 Switzerland</div>
          </button>
          <button
            class="Hotspot"
            slot="hotspot-7"
            data-position="368.42856695618354m 326.08760336551524m -87.40898857036822m"
            data-normal="0.7256656809734658m 0.6592857603877166m -0.19685427505468772m"
            data-visibility-attribute="visible"
          >
            <div class="HotspotAnnotation">📌 New York</div>
          </button>
          <button slot="ar-button" id="ar-button">
            View in your space
          </button>
          {/* <div id="ar-prompt">
            <img src="https://modelviewer.dev/shared-assets/icons/hand.png" />
          </div> */}
        </model-viewer>
      </div>
    </div>
  );
}

export default Globe;
