import React, { useState, useEffect } from "react";
import "./styles.scss";
import Globe from "../../Assets/Globe";
import Star from "../../Assets/Star";
import "../../common/styles.scss";
import useSound from "use-sound";
import Believer from "../../Assets/sounds/Believer.mp3";
import popSound from "../../Assets/sounds/pop-sound.mp3";

function Header() {
  const [play, { stop }] = useSound(popSound, { volume: 0.5 });

  const [isHovering, setIsHovering] = React.useState(false);

  const [playingR, setPlayingR] = useState(false);
  const [song, setSong] = useState("");
  const [play1, { stop: stop1, isPlaying }] = useSound(song, { volume: 0.8 });

  const [offSetY, setOffSetY] = useState(0);
  const handleScroll = () => setOffSetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="header" id="webHeader">
      <Star />

      <div className="headerContainer">
        <div className="headerContents ">
          <div className="headerText">Welcome,</div>

          <div className="name">
            <div className="title animate__animated animate__zoomInDown">
              <h1>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  I{" "}
                </span>
                &nbsp;
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  a
                </span>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  m
                </span>
                &nbsp;
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  H
                </span>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  a
                </span>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  r
                </span>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  d
                </span>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  i
                </span>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  k
                </span>
                <span
                  className="fName"
                  onMouseEnter={() => {
                    setIsHovering(true);
                    play();
                  }}
                  onMouseLeave={() => {
                    setIsHovering(false);
                    stop();
                  }}
                  isHovering={isHovering}
                >
                  .
                </span>
              </h1>
            </div>
            <div className="role">
              <p>
                i'm, <span>th3h00d</span>, n I love songs 🎧<br></br> Back-End
                (NodeJS) | Front-End (ReactJS) | Mobile (ReactNative)
              </p>
            </div>
          </div>
          <div className="header__buttons">
            <a href="mailto:hardikk2002@gmail.com" className="headbtn">
              Get In Touch
            </a>
            &nbsp;&nbsp;&nbsp;
            <p
              style={{
                position: "fixed",
                right: "3.5%",
                bottom: "3%",
              }}
              id="songButton"
              onMouseDown={() => setSong(Believer)}
              onClick={() => {
                playingR ? stop1() : play1();
                setPlayingR(!playingR);
              }}
            >
              ♬
            </p>
          </div>
        </div>
        <div className="col-md-5 col-sm-6">
          <Globe />
        </div>
      </div>
    </div>
  );
}

export default Header;
