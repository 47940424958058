import React, { useEffect, useState } from "react";
import Underlined from "../../common/underlineText";
import "./styles.scss";
import useSound from "use-sound";
import popSound from "../../Assets/sounds/pop-sound.mp3";
import Planets from "../../common/planets";

function About({ Aos }) {
  const [state] = React.useState([
    { id: 1, title: "Name:", text: "Hardik Kaushik" },
    { id: 3, title: "Github:", text: "hardikk2002" },
    { id: 4, title: "Linkedin:", text: "hardikk2002" },
    { id: 2, title: "Email:", text: "hardikk2002@gmail.com" },
  ]);

  const [play, { stop }] = useSound(popSound, { volume: 0.2 });

  const [isHovering, setIsHovering] = React.useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  // parallx
  const [offSetY, setOffSetY] = useState(0);
  const handleScroll = () => setOffSetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="about" id="webAbout">
      <div className="aboutContainer">
        <Planets />
        <div className="aboutHeading" data-aos="fade-up">
          <h1>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              A
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              b
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              ou
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              t
            </span>
            &nbsp;
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              Me
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              !
            </span>
          </h1>

          <hr />
        </div>
        <div className="aboutContent">
          <div className="aboutLeft">
            <div className="about__img moveImg">
              <div className="backgroungBox" data-aos="fade-up">
                <img
                  src="/assets/images/hardikkaushik.jpg"
                  alt="Hardik Kaushik"
                />
              </div>
            </div>
          </div>
          <div className="aboutRight">
            <h1 data-aos="fade-up">
              Hey There <span className="wave">👋</span>{" "}
            </h1>
            <p data-aos="fade-up">
              I'm Hardik, a tech enthusiast pursuing Bachelors in Computer
              Science and Engineering. I'm a{" "}
              <span>
                <Underlined text="passionate and creative developer" />
              </span>
              from India 🇮🇳, with a strong interest in Open Source Projects,
              loves to take up new challenges and learn new skills.
            </p>
            {/* I love meeting new people, exchanging ideas and spreading knowledge
            and positivity. */}
            <div className="info__contacts" data-aos="fade-up">
              <div className="row">
                {state.map((info, key) => (
                  <div className="items" data-aos="fade-up" key={key}>
                    <p className="head">{info.title}</p>
                    <p className="info">
                      {" "}
                      <span> &gt; </span> {info.text}
                    </p>
                    {/* &lt; */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
