import React from "react";
import ProjectOne from "./projectsOne";
import ProjectsTwo from "./projectsTwo";
import Button from "../../common/Button/Button";
import useSound from "use-sound";
import popSound from "../../Assets/sounds/pop-sound.mp3";
import animation from "../../Assets/img/circle-spin.svg";

function Projects() {
  const [play, { stop }] = useSound(popSound, { volume: 0.1 });

  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <div
      className="Project"
      id="webProjects"
      style={{
        width: "100%",
        // backgroundImage:
        //   "linear-gradient(to bottom, #001357f6,#000722f6, #001357)",
        display: "flex",
        marginTop: "-1px",
      }}
    >
      <div
        className="aboutContainer"
        style={{
          height: "fit-content",
        }}
      >
        <div
          className="aboutHeading"
          data-aos="fade-up"
          data-aos-offset="-150"
          // data-aos-duration="1000"
        >
          <h1>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              Som
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              e
            </span>
            &nbsp;
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              Proj
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              ects
            </span>
            &nbsp;
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              I
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              've
            </span>
            &nbsp;
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              Bui
            </span>
            <span
              className="fName"
              isHovering={isHovering}
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
            >
              lt
            </span>
          </h1>
          <hr />
        </div>

        <div
          className="projectContent"
          data-aos="fade-up"
          data-aos-offset="-150"
          // data-aos-duration="1000"
        >
          <ProjectsTwo
            // cover="https://cdn.hashnode.com/res/hashnode/image/upload/v1619266222634/lbJ9429t0.png"
            cover="https://www.youtube.com/embed/-7B4vNUgI9s"
            pName="Project Planet"
            pContent={
              <>
                {" "}
                <a
                  href="https://projectplanetsite.netlify.app/"
                  className="proLinks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Project Panet
                </a>{" "}
                is a platform where NGOs, and organizations can collaborate with
                the volunteers and other social workers so that they can work
                together and help to make our Planet a better place for living.
              </>
            }
            techs="React &nbsp; Redux &nbsp; Firebase &nbsp; Dialogflow"
            Github="https://github.com/Project-Planet/web"
            // Youtube="https://www.youtube.com/watch?v=gd1kWBldMIM"
            external="https://project-planet.hashnode.dev/project-planet-an-initiative-to-make-the-earth-a-better-place-for-living"
          />
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-150"
          // data-aos-duration="1000"
        >
          <ProjectOne
            cover="https://cdn.hashnode.com/res/hashnode/image/upload/v1619269551071/UBxj2mCRD.png"
            pName="Globe-XS"
            pContent={
              <>
                This hack is based on a real-life bank account passbook,{" "}
                <a
                  href="https://devpost.com/software/secure-bank-book"
                  target="_blank"
                  className="proLinks"
                  rel="noreferrer"
                >
                  GLOBE-XS
                </a>{" "}
                is basically an application where user can sign in after
                verifying certain Personal and Bank related credentials.
              </>
            }
            techs="Python &nbsp; SQL &nbsp; Libraries &nbsp; Python-Canvas"
            Github="https://github.com/hardikk2002/SECURE-BANK-PASSBOOK"
            external="https://devpost.com/software/secure-bank-book"
          />
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-150"
          // data-aos-duration="1000"
        >
          <ProjectsTwo
            // cover="https://cdn.hashnode.com/res/hashnode/image/upload/v1619267225357/2mk7zB9if.png"
            cover="https://www.youtube.com/embed/pO00gSfIXZ8"
            pName="Xpro: Code Editor"
            pContent={
              <>
                Xpro is a new and customizable online Code Editor. A useful and
                liberating online code editor where you can build, share and
                learn Coding by connecting with others and by making
                customizable notes.{" "}
                <a
                  href="https://hardikk2002.hashnode.dev/how-i-made-xpro-a-next-generation-code-editor"
                  className="proLinks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog Post
                </a>
              </>
            }
            techs="Next.js &nbsp; Typescript &nbsp; SocketIO &nbsp; SCSS"
            Github="https://github.com/hardikk2002/CodeEditor-Xpro"
            // Youtube="https://www.youtube.com/watch?v=pO00gSfIXZ8"
            external="https://code-editor-xpro.vercel.app/"
          />
        </div>
        <img
          src={animation}
          className="rotation shapes"
          alt=""
          style={{
            position: "absolute",
            left: "-23%",
            zIndex: "-1",
          }}
        />
        <a
          href="https://github.com/hardikk2002"
          target="_blank"
          rel="noreferrer"
          style={{
            display: "flex",
            textDecoration: "none",
          }}
          data-aos="fade-up"
          data-aos-offset="-150"
          // data-aos-duration="1000"
        >
          <Button>Show More</Button>
        </a>
      </div>
    </div>
  );
}

export default Projects;
