import React from "react";
import "./styles.scss";
import useSound from "use-sound";
import popSound from "../../Assets/sounds/pop-sound.mp3";
import git from "../../Assets/img/git.svg";
import c from "../../Assets/img/c++.svg";
import python from "../../Assets/img/python.svg";
import jquery from "../../Assets/img/jquery.svg";
import js from "../../Assets/img/js.svg";
import linux from "../../Assets/img/linux.svg";
import mongodb from "../../Assets/img/mongodb.svg";
import github from "../../Assets/img/github.svg";
import nextjs from "../../Assets/img/nextjs.svg";
import node from "../../Assets/img/nodejs.svg";
import react from "../../Assets/img/react.svg";
import scss from "../../Assets/img/sass.svg";
import ts from "../../Assets/img/ts.svg";
import vs from "../../Assets/img/vs.svg";
import webpack from "../../Assets/img/webpack.svg";
import firebase from "../../Assets/img/firebase.svg";
import npm from "../../Assets/img/npm.svg";

function Skills() {
  const [play, { stop }] = useSound(popSound, { volume: 0.2 });

  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <div
      className="Project"
      id="webSkills"
      style={{
        width: "100%",
        // backgroundImage: "linear-gradient(to bottom,  #000000 , #001357f6)",
        display: "flex",
        marginTop: "-1px",
      }}
    >
      <div className="aboutContainer">
        <div className="aboutHeading" data-aos="fade-up">
          <h1>
            <span
              className="fName"
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
              isHovering={isHovering}
            >
              M
            </span>
            <span
              className="fName"
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
              isHovering={isHovering}
            >
              y
            </span>
            &nbsp;
            <span
              className="fName"
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
              isHovering={isHovering}
            >
              Tec
            </span>
            <span
              className="fName"
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
              isHovering={isHovering}
            >
              h
            </span>
            &nbsp;
            <span
              className="fName"
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
              isHovering={isHovering}
            >
              S
            </span>
            <span
              className="fName"
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
              isHovering={isHovering}
            >
              tac
            </span>
            <span
              className="fName"
              onMouseEnter={() => {
                setIsHovering(true);
                play();
              }}
              onMouseLeave={() => {
                setIsHovering(false);
                stop();
              }}
              isHovering={isHovering}
            >
              k
            </span>
          </h1>
          <hr />
          <div className="skillContainer">
            <div className="aboutRight skillLeft">
              <br />
              <p
                style={{ fontSize: "1.4rem", lineHeight: "1.8rem" }}
                data-aos="fade-up"
              >
                I enjoy creating elegant, logical web and mobile app solutions.
                In my spare time
              </p>
              <br />
              <br />
              <h1>I Think. Code. Debug. 🚀</h1>
              <br />
              <br />
              <p className="background_blue" data-aos="fade-up">
                Why pizza 🍕 is Round and packed in Square box <br /> and eaten
                in Triangles?? 🤔
                <br />
                <a
                  href="https://twitter.com/intent/tweet?text=Yeah%2C%20I%20know%20it%20%40hardikkaushik_%20cause...%20____%20also%20checkout%20hardikkaushik.me"
                  className="animated-button1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={{ fontSize: "1.1rem" }}>Tag and Tweet</span>
                </a>
                <br />
              </p>
            </div>
            <div className="skillRight" data-aos="zoom-out-right">
              <div id="skillCanvas">
                <canvas width="590" height="590" id="myCanvas"></canvas>
              </div>
              <div id="tags">
                <ul>
                  <li>
                    <a href="/skills">
                      <img width="40px" src={js} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="40px" src={github} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="70px" src={scss} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="40px" src={vs} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="80px" src={git} alt="" />
                    </a>
                  </li>

                  <li>
                    <a href="/skills">
                      <img width="140px" src={mongodb} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="80px" src={nextjs} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="110px" src={python} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="60px" src={c} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="80px" src={npm} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="90px" src={jquery} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="90px" src={react} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="80px" src={node} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="120px" src={firebase} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="50px" src={ts} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="60px" src={webpack} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="/skills">
                      <img width="60px" src={linux} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
