import { useEffect, useState } from "react";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import CustomCursor from "./common/customCursor/index";
import About from "./components/About";
import Blogs from "./components/Blogs";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Social from "./components/social";
import Footer from "./components/Footer";
import "animate.css";
import ParticleBackground from "./Assets/Particle/ParticleBackground";
import Preloader from "./Assets/Preloader/Preloader";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // true
    // document.getElementsByClassName("userInput").readyState("load", () => {
    //   setLoading(false);
    // });
    //  window.readyState === "complete" ? setLoading(false) : setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

 const disableConsole = () => {
   console.log(
     "%c Hey thanks for coming here!",
     "background: #212121; color: #f69510; padding: 6px;"
   );
   console.log(`
    _   _                _        ____            _ 


  ██╗  ██╗ █████╗ ██████╗ ██████╗ ██╗██╗  ██╗    
  ██║  ██║██╔══██╗██╔══██╗██╔══██╗██║██║ ██╔╝    
  ███████║███████║██████╔╝██║  ██║██║█████╔╝     
  ██╔══██║██╔══██║██╔══██╗██║  ██║██║██╔═██╗     
  ██║  ██║██║  ██║██║  ██║██████╔╝██║██║  ██╗    
  ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚═╝╚═╝  ╚═╝                                                     

  
    _   ___         _       ____     ___     ___   _ 
                                                                                             
                                                             `);

   console.log = () => {};
   console.error = () => {};
 };

 return (
   <div className="App">
     {loading ? (
       <>
         <div className="particle"> </div>
         <Preloader />
       </>
     ) : (
       <>
         <ParticleBackground />
         {/* <CustomCursor /> */}
         <Navbar />
         <Header Aos={Aos} />
         <About Aos={Aos} />
         <Skills Aos={Aos} />
         <Projects Aos={Aos} />
         <Blogs Aos={Aos} />
         <Social Aos={Aos} />
         <Footer />
       </>
     )}
     {disableConsole()}
     {/* <Song /> */}
   </div>
 );
}

export default App;
