
export const background_color1 = "#00061b";
export const box_shadow = "0 7px 10px rgba(0, 22, 70, 0.274)";
export const header_font = "'Fredoka One', sans-serif";
export const content_font = "'Rubik', sans-serif";
export const normal_font = "'Open Sans', sans-serif";


export const purple = "#6a2aff";
export const red = "rgb(255, 0, 53)";
export const orange = "#F0AA7B";
export const blue = "#19cbe7";
export const hovblue = "#00dbdb17";
export const gh = "#000722f6";

export const dblue = "#0087ca";
export const grey = " rgb(207, 207, 207)";
export const white = "#f1f1f1";
export const blueGrey = " #95B0B6";


