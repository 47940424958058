import React from 'react'
import "./styles.scss"
import Button from "../../common/Button/Button"

import MenuOpenIcon from '@material-ui/icons/MenuOpen';

function Navbar() {

    const [toggle, setToggle] = React.useState(true);

    return (
      <div className="navBar container">
        <div className="navContainer my-4">
          <div className="leftContainer animate__animated animate__flipInX">
            <h1>
              <span>th3</span>h00d
            </h1>
          </div>
          <div className="rightContainer">
            {toggle ? (
              <div className="rightContainer1">
                <div className="navItems animate__animated animate__fadeInLeft">
                  {/* <a
                    href="/#webHeader"
                    className="animate__animated animate__slideInRight"
                  >
                    Home
                  </a> */}
                  <a
                    href="/#webAbout"
                    target="_blank"
                    rel="noreferrer"
                    className="animate__animated animate__slideInRight"
                  >
                    About
                  </a>
                  <a
                    href="/#webSkills"
                    target="_blank"
                    rel="noreferrer"
                    className="animate__animated animate__slideInRight"
                  >
                    Skills
                  </a>
                  <a
                    href="https://github.com/hardikk2002"
                    target="_blank"
                    rel="noreferrer"
                    className="animate__animated animate__slideInRight"
                  >
                    Github
                  </a>
                  <a
                    href="https://hardik-blogs.hashnode.dev/"
                    target="_blank"
                    rel="noreferrer"
                    className="animate__animated animate__slideInRight"
                  >
                    Blogs
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="animate__animated animate__slideInRight "
                  >
                    <Button>Resume</Button>
                  </a>
                </div>
              </div>
            ) : (
              <div className="rightContainer2">
                <div className="navItems ">
                  <a
                    href="/#webHeader"
                    className="animate__animated animate__slideInRight"
                  >
                    Home
                  </a>
                  <a
                    href="/#webAbout"
                    className="animate__animated animate__slideInRight"
                  >
                    About
                  </a>
                  <a
                    href="/#webSkills"
                    className="animate__animated animate__slideInRight"
                  >
                    Skills
                  </a>
                  <a
                    href="/#webProjects"
                    className="animate__animated animate__slideInRight"
                  >
                    Pojects
                  </a>
                  <a
                    className="animate__animated animate__slideInRight"
                    href="https://hardik-blogs.hashnode.dev/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blogs
                  </a>
                  <a
                    href="https://github.com/hardikk2002"
                    target="_blank"
                    rel="noreferrer"
                    className="animate__animated animate__slideInRight"
                  >
                    Github
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="animate__animated animate__slideInRight"
                  >
                    <Button>Resume</Button>
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="toggle" onClick={() => setToggle(!toggle)}>
            <MenuOpenIcon />
          </div>
        </div>
      </div>
    );
}

export default Navbar;