import React from 'react'
import "./styles.scss"
import Particles from 'react-particles-js';
import particleConfigs from "../../config/particlesjs-config";

function ParticleBackground() {
    return (
        <div>
            <Particles params={particleConfigs}
                className="particle"
            ></Particles>
        </div>
    )
}

export default ParticleBackground
