import React from 'react';
import "./styles.scss"

function Star() {
    return (

        <div className="night">
            <div className="shooting_star"></div>
            <div className="shooting_star"></div>
            <div className="shooting_star"></div>
            <div className="shooting_star"></div>
            <div className="shooting_star"></div>
            <div className="shooting_star"></div>
        </div>

    )
}

export default Star
