import styled from "styled-components"

const Button = styled.button`
  margin-left: auto;
  margin-right: auto;

  outline: none;
  border: none;
  color: ${(props) => props.theme.blue};
  text-decoration: none;
  background-color: #19cce713;
  border: 2.2px solid ${(props) => props.theme.blue};
  border-radius: 19px;
  padding: 0.5rem 1.3rem;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.5s ease-out;
  font-family: ${(props) => props.theme.content_font};
  cursor: pointer;

  &:hover {
    transform: scale(0.97);
    background-color: ${(props) => props.theme.hovblue};
  }
  &:active {
    transform: scale(1.05, 1.05);
  }
`;

export default Button;