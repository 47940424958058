import React, { useRef, useEffect } from 'react'
import styled from "styled-components";


const CustomCursor = styled.div`
  @media(max-width: 950px){
    display: none;
  }
`;

const Cursor = () => {
  const cursorRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousemove", (event) => {
      const { clientX, clientY } = event;
      const mouseX = clientX - cursorRef.current?.clientWidth / 2;
      const mouseY = clientY - cursorRef.current?.clientHeight / 2;
      cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 200px)`;

    })
  }, [])
  return (
    <CustomCursor id="cursorChange" ref={cursorRef}

      style={{
        zIndex: "9999",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        border: "2px solid #95B0B6",
        pointerEvents: "none",
        overflow: "hidden",
        transform: "translate3d(0px,00px,0)",
        position: "fixed",
      }}
    ></CustomCursor>

  )
}

export default Cursor
