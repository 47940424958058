import React, { useEffect } from "react";
import styled from "styled-components";
import HorizontalScroll from "./HorizontalScroll";
import Button from "../../common/Button/Button";
import BlogCards from "./BlogCards";
import useSound from "use-sound";
import popSound from "../../Assets/sounds/pop-sound.mp3";
import bgImage from "../../Assets/img/bg3.png";

const HorizontalSection = styled.section`
  width: 100%;
`;

const CardsContainer = styled.div`
  margin-top: 2%;
  position: relative;
  height: 60%;
  padding: 0 0px 0 150px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;
const Heading = styled.div`
  width: 350px;

  @media (max-width: 750px) {
    width: 100px;
    padding-right: 20%;
  }
`;

const HR = styled.div`
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  border-radius: 5px;
  width: 100%;
  color: #90a6fc;
  font-size: 2rem;
  font-weight: 900;

  ${
    "" /* ::after{    
        content: "➤⤚";

    } */
  }
  ::before {
    content: "⟿";
  }
`;

function Blogs() {
  const [play, { stop }] = useSound(popSound, { volume: 0.1 });

  const [isHovering, setIsHovering] = React.useState(false);
  return (
    <div
      id="blogs"
      style={{
        width: "100%",
        // backgroundImage: `url(${bgImage})`,
        // backgroundRepeat: "no-repeat",
        // backgroundColor: "#000722f6",
        display: "flex",
        marginTop: "0",
        // linear-gradient(to bottom,  #001357f6,#000722f6,#001357f6)
      }}
    >
      <div
        className="aboutContainer"
        // style={{
        //   height: "fit-content",
        // }}
      >
        <div className="blogPosts">
          {/* <div className="left">

                    </div>
                    <div className="left">

                    </div> */}

          <HorizontalSection>
            <HorizontalScroll>
              <CardsContainer>
                <Heading>
                  <div
                    className="aboutHeading"
                    // data-aos="fade-left"
                    // data-aos-offset="-150"
                    // data-aos-duration="1000"
                  >
                    <h1>
                      <span
                        className="fName"
                        isHovering={isHovering}
                        onMouseEnter={() => {
                          setIsHovering(true);
                          play();
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}
                      >
                        Bl
                      </span>
                      <span
                        className="fName"
                        isHovering={isHovering}
                        onMouseEnter={() => {
                          setIsHovering(true);
                          play();
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}
                      >
                        o
                      </span>
                      <span
                        className="fName"
                        isHovering={isHovering}
                        onMouseEnter={() => {
                          setIsHovering(true);
                          play();
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}
                      >
                        gs
                      </span>
                      &nbsp;
                      <span
                        className="fName"
                        isHovering={isHovering}
                        onMouseEnter={() => {
                          setIsHovering(true);
                          play();
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}
                      >
                        I'
                      </span>
                      <span
                        className="fName"
                        isHovering={isHovering}
                        onMouseEnter={() => {
                          setIsHovering(true);
                          play();
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}
                      >
                        ve
                      </span>
                      &nbsp;
                      <span
                        className="fName"
                        isHovering={isHovering}
                        onMouseEnter={() => {
                          setIsHovering(true);
                          play();
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}
                      >
                        Writ
                      </span>
                      <span
                        className="fName"
                        isHovering={isHovering}
                        onMouseEnter={() => {
                          setIsHovering(true);
                          play();
                        }}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          stop();
                        }}
                      >
                        ten
                      </span>
                    </h1>
                    <HR></HR>
                  </div>
                </Heading>

                <BlogCards
                  imageLink="https://cdn.hashnode.com/res/hashnode/image/upload/v1619267225357/2mk7zB9if.png"
                  heading="How I made Xpro: A Code Editor!"
                  smallContent={
                    <>
                      Are you bored with those old and compact Code Editors❓ So
                      here I am presenting you the ✖️pro.{" "}
                      <a className="proLinks">Xpro</a> is a new and...
                    </>
                  }
                  blogLink="https://hardik-blogs.hashnode.dev/how-i-made-xpro-a-next-generation-code-editor"
                  likes="33"
                  comments="6"
                />
                <BlogCards
                  imageLink="https://cdn.hashnode.com/res/hashnode/image/upload/v1631616016349/sSqb0roK7.png"
                  heading="Dev Essentials: My own Chrome Extension ✨️"
                  smallContent={
                    <>
                      <a className="proLinks">Dev Essentials</a> is a Chrome
                      Extension built for Developers and Tech Enthusiasts, to
                      keep them updated...
                    </>
                  }
                  blogLink="https://hardik-blogs.hashnode.dev/dev-essentials-a-chrome-extension-to-keep-you-updated-with-the-latest-happenings"
                  likes="79"
                  comments="4"
                />

                <BlogCards
                  imageLink="https://cdn.hashnode.com/res/hashnode/image/upload/v1622028641209/-LuQOYBFr1.png"
                  heading="Data Structure & Algorithms MATHS"
                  smallContent={
                    <>
                      Maths simply make a person more logical, creative, and
                      intelligence First things, first, i.e{" "}
                      <a className="proLinks">MATHEMATICS</a> . So do you...
                    </>
                  }
                  blogLink="https://hardik-blogs.hashnode.dev/data-structure-and-algorithms-linked-list-quick-revision-series-part-31"
                  likes="18"
                  comments="1"
                />

                <a
                  href="https://hardik-blogs.hashnode.dev/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>More➤</Button>
                </a>
              </CardsContainer>
            </HorizontalScroll>
          </HorizontalSection>
          {/* 
                    <h1>PORTFOLIO IS IN DEVELOPMENT PHASE</h1>
                    <p>I will complete it soon, Thanks for understanding</p> */}
        </div>
      </div>
    </div>
  );
}

export default Blogs;
