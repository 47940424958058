import React from "react";
import vercel from "../../Assets/img/vercel.svg";
import "./index.scss";

const index = () => {
  return (
    <div className="footerContainer">
      <div className="footer">
        <div className="innerFooter">
          <p>
            Inspired from many, build by&nbsp;
            <span className="animated-button1">
              <a className="proLinks" style={{ fontSize: "1.1rem" }}>
                Hardik
              </a>
            </span>
            🙋
          </p>
        </div>
        <div className="innerFooter">
          {/* <p>
            Powered by{" "}
            <span className="footerLogo">
              <img src={vercel} alt="Vercel Logo" width={72} height={16} />
            </span>{" "}
          </p> */}
          <p>
            {" "}
            <a
              href="https://twitter.com/intent/tweet?text=Hey%2C%20checkout%20%40this%20website%20at%20hardikkaushik.me%2C%20I%20liked%20it..."
              target="_blank"
              className="proLinks"
              without
              rel="noreferrer"
              style={{ fontSize: "1.1rem" }}
            >
              Share Your <span style={{ fontWeight: "100" }}>❤️</span>
            </a>{" "}
          </p>
        </div>
        {/* <div className="innerFooter">
          <p>Assets credits to be given. </p>
        </div> */}
      </div>
    </div>
  );
};

export default index;
