import React, { useState, useEffect } from "react";
import "./index.scss";
import Parallax from "parallax-js";

const Planets = () => {
  useEffect(() => {
    const scene = document.getElementById("scene");
    new Parallax(scene);
  });
  useEffect(() => {
    const scene1 = document.getElementById("scene1");
    new Parallax(scene1);
  });


  // parallax
  const [offSetY, setOffSetY] = useState(20);
  const handleScroll = () => setOffSetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <div className="item2" id="scene">
        {/* project */}
        <img
          src="assets/planet/neptune.png"
          id="shapes"
          alt="shape"
          data-depth="-2"
          style={{
            transform: `translateY(${offSetY * 0.05}px)`,
          }}
        />
      </div>
      {/* skill */}
      <div className="item3" id="scene1">
        <img
          src="assets/planet/saturn.png"
          id="shapes"
          alt="shape"
          data-depth="-3"
          style={{
            transform: `translateY(${offSetY * 0.05}px)`,
          }}
        />
      </div>

      <div className="item5">
        <img
          src="assets/planet/rock.png"
          id="shapes"
          alt="shape"
          style={{
            transform: `translateY(${offSetY * 0.05}px)`,
          }}
        />
      </div>
      <div className="item6">
        {/* blog */}
        <img
          src="assets/planet/mercury.png"
          id="shapes"
          alt="shape"
          style={{
            transform: `translateY(${offSetY * 0.05}px)`,
          }}
        />
      </div>

      <div className="item7">
        {/* blog */}
        <img
          src="assets/planet/uranus.png"
          id="shapes"
          alt="shape"
          style={{
            transform: `translateY(${offSetY * 0.05}px)`,
          }}
        />
      </div>
    </div>
  );
};

export default Planets;
